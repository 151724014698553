import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/pst/";
const regUlang = {
  namespaced: true,
  state: {
    agama: [],
    jenjangPen: [],
    pekerjaan: [],
    penghasilan: [],
    regsUlang: [],
    wilayah: []
  },
  getters: {
    wilayah: state => state.wilayah,
    agama: state => state.agama,
    jenjangPen: state => state.jenjangPen,
    pekerjaan: state => state.pekerjaan,
    penghasilan: state => state.penghasilan,
    getDaful: state => state.regsUlang
  },
  mutations: {
    SET_WILAYAH: (state, wilayah) => (state.wilayah = wilayah),
    SET_AGAMA: (state, agama) => (state.agama = agama),
    SET_JENJANG: (state, jenjangPen) => (state.jenjangPen = jenjangPen),
    SET_PEKERJAAN: (state, pekerjaan) => (state.pekerjaan = pekerjaan),
    SET_PENGHASILAN: (state, penghasilan) => (state.penghasilan = penghasilan),
    SET_DAFUL: (state, regsUlang) => (state.regsUlang = regsUlang)
  },
  actions: {
    async loadDaful({ commit }, kode) {
      const response = await axios.post(resource_uri + "cekKode", kode);
      commit("SET_DAFUL", response.data);
    },
    async loadAgama({ commit }) {
      const response = await axios.get(resource_uri + "agama");
      commit("SET_AGAMA", response.data);
    },
    async loadJenjang({ commit }) {
      const response = await axios.get(resource_uri + "jenjangPend");
      commit("SET_JENJANG", response.data);
    },
    async loadPekerjaan({ commit }) {
      const response = await axios.get(resource_uri + "pekerjaan");
      commit("SET_PEKERJAAN", response.data);
    },
    async loadPenghasilan({ commit }) {
      const response = await axios.get(resource_uri + "penghasilan");
      commit("SET_PENGHASILAN", response.data);
    },
    async loadWilayah({ commit }) {
      const response = await axios.get(resource_uri + "wilayah");
      commit("SET_WILAYAH", response.data);
    },
    async updateReg({ commit }, regsUlang) {
      const response = await axios.put(
        `${resource_uri + "daftarUlang/"}${regsUlang.id_pst}`,
        regsUlang
      );
      commit("SET_DAFUL", response.data);
    }
  }
};
export default regUlang;
