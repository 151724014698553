import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Lending",
    component: () =>
      import(/* webpackChunkName: "lending" */ "@/views/Lending.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/components/SignIn.vue")
  },
  {
    path: "/resetPassword/:kode",
    name: "resetPassword",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "@/views/ResetPassword.vue"
      )
  },
  {
    path: "/aktivasi/:kode",
    name: "aktivasi",
    component: () =>
      import(/* webpackChunkName: "aktivasi" */ "@/views/Aktivasi.vue")
  },
  {
    path: "/admin",
    // name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/Admin.vue"),
    meta: {
      permission: "1"
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/userProfil",
        name: "userProfil",
        component: () =>
          import(
            /* webpackChunkName: "userProfil" */
            "@/views/UserProfil.vue"
          ),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/jadwal",
        name: "jadwal",
        component: () =>
          import(/* webpackChunkName: "jadwal" */ "@/views/Jadwal.vue"),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/peserta",
        name: "peserta",
        component: () =>
          import(/* webpackChunkName: "peserta" */ "@/views/Peserta.vue"),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/dataPeserta",
        name: "dataPeserta",
        component: () =>
          import(
            /* webpackChunkName: "dataPeserta" */ "@/views/dataPeserta.vue"
          ),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/daftarUlang",
        name: "daftarUlang",
        component: () =>
          import(
            /* webpackChunkName: "daftarUlang" */ "@/views/DaftarUlang.vue"
          ),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/mahasiswabaru",
        name: "mahasiswaBaru",
        component: () =>
          import(
            /* webpackChunkName: "mahasiswaBaru" */ "@/components/MahasiswaBaru.vue"
          ),
        meta: {
          permission: "1"
        }
      },
      {
        path: "/sync",
        name: "sync",
        component: () =>
          import(/* webpackChunkName: "sync" */ "@/components/Sync.vue"),
        meta: {
          permission: "1"
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      let permission = JSON.parse(localStorage.getItem("user") || "0");
      if (to.matched.some(record => record.meta.permission)) {
        if (permission.role === to.meta.permission) {
          next();
        } else {
          next("/503");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "/user",
    //name: "User",
    component: () => import(/* webpackChunkName: "user" */ "@/views/User.vue"),
    meta: {
      permission: "2"
    },
    children: [
      {
        path: "",
        name: "Daftar",
        component: () =>
          import(/* webpackChunkName: "daftar" */ "@/views/Daftar.vue"),
        meta: {
          permission: "2"
        }
      },
      {
        path: "/registrasiUlang",
        name: "registrasiUlang",
        component: () =>
          import(
            /* webpackChunkName: "registrasiUlang" */
            "@/views/StepperReg.vue"
          ),
        meta: {
          permission: "2"
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      let permission = JSON.parse(localStorage.getItem("user") || "0");
      if (to.matched.some(record => record.meta.permission)) {
        if (permission.role === to.meta.permission) {
          next();
        } else {
          next("/503");
        }
      } else {
        next();
      }
    }
  },
  {
    path: "*",
    name: "notFound",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue")
  }
];
const router = new VueRouter({
  // mode: "hash",
  mode: "history",
  // base: process.env.BASE_URL,
  base: __dirname,
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/",
    "/resetPassword/" + to.params.kode,
    "/aktivasi/" + to.params.kode
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");
  if (authRequired && !loggedIn) {
    return next("/login");
  } else {
    next();
  }
});
export default router;
