import Vue from "vue";
import Vuex from "vuex";
//import User from "./modules/user";
import users from "./modules/users";
import auth from "./modules/auth";
import jadwals from "./modules/jadwals";
import daftar from "./modules/daftar";
import peserta from "./modules/peserta";
import reg from "./modules/reg";
import regUlang from "./modules/regUlang";
import dashboard from "./modules/dashboard";
import maba from "./modules/maba";
import biaya from "./modules/biaya";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    auth,
    jadwals,
    daftar,
    peserta,
    reg,
    regUlang,
    dashboard,
    maba,
    biaya
  }
});
