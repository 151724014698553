import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/peserta/";
const base_uri = "https://cbt.unikarta.ac.id/api/";
const peserta = {
  namespaced: true,
  state: {
    peserta: [],
    gelombang: [],
    dataPeserta: [],
    cbt: []
  },
  getters: {
    allPeserta: state => state.peserta,
    gel: state => state.gelombang,
    dataPst: state => state.dataPeserta || null,
    getCbt: state => state.cbt
  },
  mutations: {
    SET_NEW_CBT: (state, cbt) => state.cbt.unshift(cbt),
    SET_CBT: (state, cbt) => (state.cbt = cbt),
    SET_DATAPESERTA: (state, dataPeserta) => (state.dataPeserta = dataPeserta),
    SET_PESERTA: (state, peserta) => (state.peserta = peserta),
    SET_GELOMBANG: (state, gelombang) => (state.gelombang = gelombang),
    UPDATE_PESERTA: (state, updatedPeserta) => {
      const index = state.peserta.findIndex(t => t.id === updatedPeserta.id);
      if (index !== -1) {
        state.peserta.splice(index, 1, updatedPeserta);
      }
    },
    DELETE_PESERTA: (state, pst) =>
      (state.peserta = state.peserta.filter(p => pst.id !== p.id))
  },
  actions: {
    async loadPeserta({ commit }) {
      const response = await axios.get(resource_uri);
      commit("SET_PESERTA", response.data);
    },
    async loadCbt({ commit }) {
      const response = await axios.get(base_uri + "ujian", {
        headers: {
          "API-KEY": `alkuja07`
        }
      });
      commit("SET_CBT", response.data.data);
    },
    async loadJadwal({ commit }) {
      const response = await axios.get(resource_uri + "gelombang");
      commit("SET_GELOMBANG", response.data);
    },
    async loadDataPst({ commit }, fromData) {
      const response = await axios.post(resource_uri + "dataPeserta", fromData);
      commit("SET_DATAPESERTA", response.data);
    },
    async validasi({ commit }, valid) {
      const response = await axios.put(
        `${resource_uri + "validasi/"}${valid.id_pst}`,
        valid
      );
      let newValidasi = response.data;
      commit("UPDATE_PESERTA", newValidasi);
      return newValidasi;
    },
    async updatePst({ commit }, updPst) {
      const response = await axios.put(
        `${resource_uri}${updPst.id_pst}`,
        updPst
      );
      let newPst = response.data;
      commit("UPDATE_PESERTA", newPst);
      return newPst;
    },
    async addCbt({ commit }, fromCbt) {
      let key = "alkuja07";
      const response = await axios.post(base_uri + "ujian", fromCbt, {
        headers: {
          "API-KEY": ` ${key}`
        }
      });
      commit("SET_NEW_CBT", response.data);
    },
    async updateLulus({ commit }, lulus) {
      const response = await axios.put(
        `${resource_uri + "lulus/"}${lulus.id_pst}`,
        lulus
      );
      let newLulus = response.data;
      commit("UPDATE_PESERTA", newLulus);
      return newLulus;
    },
    async removePst({ commit }, pst) {
      const response = await axios.delete(
        `${resource_uri + "hapus/"}${pst.id}`
      );
      if (response.status == 201 || response.status == 502) {
        commit("DELETE_PESERTA", pst);
      }
    }
  }
};
export default peserta;
