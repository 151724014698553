import axios from "axios";
const base_uri = "https://api-spmb.unikarta.ac.id/api/registrasi/";
const reg = {
  namespaced: true,
  state: {
    dataReg: [],
    prodi: []
  },
  getters: {
    dataReg: state => state.dataReg || null,
    prodi: state => state.prodi
  },
  mutations: {
    SET_DATA_REG: (state, dataReg) => (state.dataReg = dataReg),
    SET_NEW_NIM: (state, nim) => state.dataReg.unshift(nim),
    SET_PRODI: (state, prodi) => (state.prodi = prodi),
    UPDATE_KLS: (state, updatedKls) => {
      const index = state.dataReg.findIndex(t => t.id === updatedKls.id);
      if (index !== -1) {
        state.dataReg.splice(index, 1, updatedKls);
      }
    }
  },
  actions: {
    async loadDataReg({ commit }, fromData) {
      const response = await axios.post(base_uri + "dataRegistrasi", fromData);
      commit("SET_DATA_REG", response.data);
    },
    async setNimPst({ commit }, fromData) {
      const response = await axios.post(base_uri + "setNim", fromData);
      let newNim = response.data;
      commit("SET_NEW_NIM", newNim);
      return newNim;
    },
    async loadProdi({ commit }) {
      const response = await axios.get(base_uri + "prodi");
      commit("SET_PRODI", response.data);
    },
    async updateKlsProdi({ commit }, kls) {
      const response = await axios.put(
        `${base_uri + "updateKelasProdi/"}${kls.id}`,
        kls
      );
      let newKlsProdi = response.data;
      commit("UPDATE_KLS", newKlsProdi);
      return newKlsProdi;
    }
  }
};
export default reg;
