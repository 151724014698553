import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSimpleAlert from "vue-simple-alert";
import VueProgressBar from "vue-progressbar";
import VueQRCodeComponent from "vue-qr-generator";
import VueCurrencyFilter from "vue-currency-filter";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(Viewer);
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(VueProgressBar, {
  color: "rgb(19,217,45)",
  failedColor: "red",
  height: "10px",
  thickness: "10px"
});
Vue.use(VueCurrencyFilter, {
  symbol: "Rp.",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true
});
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false;
import axios from "axios";
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    //!request token
    axios.interceptors.request.use(function(config) {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        config.headers.common["Authorization"] = token;
      }
      return config;
    });
    //!untuk cek role user..
    const token = JSON.parse(localStorage.getItem("token"));
    if (token) {
      this.$store.dispatch("auth/isLogin");
    }
    // !untuk loading data
    axios.interceptors.request.use(config => {
      this.$Progress.start(); // for every request start the progress
      return config;
    });
    axios.interceptors.response.use(response => {
      this.$Progress.finish(); // finish when a response is received
      return response;
    });
    axios.interceptors.response.use(
      response => response,
      error => {
        if (error) {
          this.$Progress.fail(); // finish when a response is received
        }
        return Promise.reject(error);
      }
    );
    //!response error
    axios.interceptors.response.use(
      response => response,
      error => {
        console.log(error.response);
        if (error.response.status === 400) {
          this.$router.push("/login");
          this.$store.dispatch("auth/logout");
        }
        return Promise.reject(error);
      }
    );
  }
}).$mount("#app");
