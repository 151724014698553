import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/authentication/";
const auth = {
  namespaced: true,
  state: {
    user: null,
    token: null,
    mail: null,
    newPassword: null,
    newAct: null
  },
  getters: {
    userInfo(state) {
      let user = state.user;
      if (!user) {
        user = JSON.parse(window.localStorage.getItem("user"));
      }
      return user;
    },
    getToken(state) {
      let token = state.token;
      if (!token) {
        token = JSON.parse(window.localStorage.getItem("token"));
      }
      return token;
    }
  },
  mutations: {
    LOGOUT() {
      localStorage.removeItem("user");
      localStorage.removeItem("dataPst");
      localStorage.removeItem("token");
      location.reload();
    },
    SET_USER_DATA(state, data) {
      localStorage.setItem("user", JSON.stringify(data));
      state.user = data;
    },
    SET_TOKEN(state, data) {
      localStorage.setItem("token", JSON.stringify(data));
      state.token = data;
    },
    SET_SEND_MAIL: (state, mail) => (state.mail = mail),
    SET_NEW_PASSWORD: (state, newPassword) => (state.newPassword = newPassword),
    SET_AKTIV: (state, newAct) => (state.newAct = newAct)
  },
  actions: {
    async loginUser({ commit }, credentials) {
      let response = await axios.post(resource_uri + "login", credentials);
      let users = await axios.get(resource_uri + "user", {
        headers: {
          Authorization: response.data.data.token
        }
      });
      commit("SET_TOKEN", response.data.data.token);
      commit("SET_USER_DATA", users.data);
    },
    async isLogin({ commit }) {
      let users = await axios.get(resource_uri + "user");
      commit("SET_USER_DATA", users.data);
    },
    async register({ commit }, credentials) {
      return await axios
        .post(resource_uri + "registration", credentials)
        .then(({ data }) => {
          commit("SET_USER_DATA", data);
        });
    },
    async sendMail({ commit }, credentials) {
      const response = await axios.post(
        resource_uri + "forgotPassword",
        credentials
      );
      let newEmail = response.data;
      commit("SET_SEND_MAIL", newEmail);
      return newEmail;
    },
    async resetPassword({ commit }, credentials) {
      const pass = await axios.put(
        resource_uri + "resetPassword",
        credentials,
        {
          headers: {
            Authorization: credentials.token
          }
        }
      );
      let newPassword = pass.data;
      commit("SET_NEW_PASSWORD", newPassword);
      return newPassword;
    },
    async activation({ commit }, credentials) {
      const act = await axios.put(resource_uri + "aktivasi", credentials, {
        headers: {
          Authorization: credentials.token
        }
      });
      let newAct = act.data;
      commit("SET_AKTIV", newAct);
      return newAct;
    },
    logout({ commit }) {
      commit("LOGOUT");
    }
  }
};
export default auth;
