import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/jadwal/";
const jadwals = {
  namespaced: true,
  state: {
    jadwal: []
  },
  getters: {
    allJadwal: state => state.jadwal
  },
  mutations: {
    SET_JADWAL: (state, jadwal) => (state.jadwal = jadwal),
    SET_NEW_JADWAL: (state, jadwal) => state.jadwal.unshift(jadwal),
    DELETE_JADWAL: (state, jad) =>
      (state.jadwal = state.jadwal.filter(j => jad.id !== j.id)),
    UPDATE_JADWAL: (state, updatedJadwal) => {
      const index = state.jadwal.findIndex(t => t.id === updatedJadwal.id);
      if (index !== -1) {
        state.jadwal.splice(index, 1, updatedJadwal);
      }
    }
  },
  actions: {
    async loadJadwal({ commit }) {
      const response = await axios.get(resource_uri);
      commit("SET_JADWAL", response.data);
    },
    async addJadwal({ commit }, jadwal) {
      const response = await axios.post(resource_uri, jadwal);
      commit("SET_NEW_JADWAL", response.data);
    },
    async removeJadwal({ commit }, jad) {
      const response = await axios.delete(`${resource_uri}${jad.id}`);
      if (response.status == 201 || response.status == 502) {
        commit("DELETE_JADWAL", jad);
      }
    },
    async updateJadwal({ commit }, jadwal) {
      const response = await axios.put(`${resource_uri}${jadwal.id}`, jadwal);
      let newJadwal = response.data;
      commit("UPDATE_JADWAL", newJadwal);
      return newJadwal;
    }
  }
};
export default jadwals;
