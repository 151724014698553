import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/biaya";
const biaya = {
  namespaced: true,
  state: {
    dataBiaya: null
  },
  getters: {
    allBiaya: state => state.dataBiaya
  },
  mutations: {
    SET_BIAYA: (state, dataBiaya) => (state.dataBiaya = dataBiaya)
  },
  actions: {
    async loadBiaya({ commit }) {
      const response = await axios.get(resource_uri);
      commit("SET_BIAYA", response.data.biaya);
    }
  }
};
export default biaya;
