import axios from "axios";
const base_uri = "https://api-spmb.unikarta.ac.id/api/dashboard/";
const dashboard = {
  namespaced: true,
  state: {
    dash: []
  },
  getters: {
    jlhDaftar: state => state.dash.jlhDaftar || "",
    dafarUlang: state => state.dash.daftarUlang,
    jlhProdi: state => state.dash.prodi || [],
    jlhPst: state => state.dash.jlhPst,
    lulus: state => state.dash.lulus,
    gagal: state => state.dash.gagal
  },
  mutations: {
    SET_DATA_DASH: (state, dash) => (state.dash = dash)
  },
  actions: {
    async loadDataDahs({ commit }) {
      const response = await axios.get(base_uri);
      commit("SET_DATA_DASH", response.data);
    }
  }
};
export default dashboard;
