import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/user/";
const users = {
  namespaced: true,
  state: {
    dataUser: []
  },
  getters: {
    allUser: state => state.dataUser,
    totalUser: state => state.dataUser.length
  },
  mutations: {
    SET_USER: (state, dataUser) => (state.dataUser = dataUser),
    DELETE_USER: (state, user) =>
      (state.dataUser = state.dataUser.filter(t => user.id !== t.id)),
    UPDATE_USER: (state, updatedUser) => {
      const index = state.dataUser.findIndex(t => t.id === updatedUser.id);
      if (index !== -1) {
        state.dataUser.splice(index, 1, updatedUser);
      }
    }
  },
  actions: {
    async loadUser({ commit }) {
      const response = await axios.get(resource_uri);
      commit("SET_USER", response.data);
    },
    async removeUser({ commit }, user) {
      const response = await axios.delete(`${resource_uri}${user.id}`);
      if (response.status == 201 || response.status == 502) {
        commit("DELETE_USER", user);
      }
    },
    async updateUser({ commit }, user) {
      const response = await axios.put(`${resource_uri}${user.id}`, user);
      let newUser = response.data;
      commit("UPDATE_USER", newUser);
      return newUser;
    }
  }
};
export default users;
