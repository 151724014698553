import axios from "axios";
const resource_uri = "https://api-spmb.unikarta.ac.id/api/pst/";
const daftar = {
  namespaced: true,
  state: {
    daftar: [],
    // dataPst: JSON.parse(localStorage.getItem(`dataPst`)) || [] || null,
    dataPst: []
  },
  getters: {
    // getPstByid: (state, dataPst) =>
    //   (state.dataPst = state.dataPst.find((t) => dataPst.id === t.id)),
    getPstByid(state, dataPst) {
      let pst = state.dataPst.find(t => dataPst.id === t.id);
      if (!pst) {
        pst = JSON.parse(localStorage.getItem("dataPst") || null);
      }
      return pst;
    }
  },
  mutations: {
    SET_NEW_PST: (state, daftar) => state.daftar.unshift(daftar),
    // SET_PST: (state, dataPst) => (state.dataPst = dataPst),
    SET_PST(state, dataPst) {
      localStorage.setItem(`dataPst`, JSON.stringify(dataPst));
      state.dataPst = dataPst;
    },
    UPDATE_PST: (state, updatedPst) => {
      const index = state.daftar.findIndex(t => t.id === updatedPst.id);
      if (index !== -1) {
        state.daftar.splice(index, 1, updatedPst);
      }
    }
  },
  actions: {
    async loadpst({ commit }, pst) {
      const response = await axios.get(`${resource_uri}${pst}`);
      commit("SET_PST", response.data);
    },
    async daftarPst({ commit }, daftar) {
      const response = await axios.post(resource_uri + "daftar", daftar);
      commit("SET_NEW_PST", response.data);
    },
    async updateProfil({ commit }, bio) {
      const response = await axios.put(`${resource_uri}${bio.id_pst}`, bio);
      let newPst = response.data;
      commit("UPDATE_PST", newPst);
      return newPst;
    },
    async updateAsalPendidikan({ commit }, asal) {
      const response = await axios.put(
        `${resource_uri + "asalPendidikan/"}${asal.id_pst}`,
        asal
      );
      let newPst = response.data;
      commit("UPDATE_PST", newPst);
      return newPst;
    },
    async updatePeminatan({ commit }, peminatan) {
      const response = await axios.put(
        `${resource_uri + "peminatan/"}${peminatan.id_pst}`,
        peminatan
      );
      let newPst = response.data;
      commit("UPDATE_PST", newPst);
      return newPst;
    },
    async updatePoto({ commit }, formData) {
      const response = await axios.post(resource_uri + "updatePoto", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    },
    async updateIjazah({ commit }, formIjazah) {
      const response = await axios.post(
        resource_uri + "updateIjazah",
        formIjazah,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      commit("UPDATE_PST", response.data);
    },
    async uploadKwitansi({ commit }, formKwitansi) {
      const response = await axios.post(
        resource_uri + "kwitansi",
        formKwitansi,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      commit("UPDATE_PST", response.data);
    },
    async uploadKk({ commit }, formKk) {
      const response = await axios.post(resource_uri + "kk", formKk, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    },
    async uploadKtp({ commit }, formKtp) {
      const response = await axios.post(resource_uri + "ktp", formKtp, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    },

    async updateBuktiReg({ commit }, formData) {
      const response = await axios.post(resource_uri + "buktiReg", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    },
    async uploadTranskrip({ commit }, formTr) {
      const response = await axios.post(resource_uri + "transkrip", formTr, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    },
    async uploadSuratPindah({ commit }, formSp) {
      const response = await axios.post(resource_uri + "suratPindah", formSp, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      commit("UPDATE_PST", response.data);
    }
  }
};
export default daftar;
