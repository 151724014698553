import axios from "axios";
const base_uri = "https://api-spmb.unikarta.ac.id/api/maba/";
const base_url = "https://api.unikarta.ac.id/api/mhs";
const maba = {
  namespaced: true,
  state: {
    maba: [],
    mhs: []
  },
  getters: {
    DataMaba: state => state.maba.dataMaba,
    diterima: state => state.maba.diterima,
    seleksi: state => state.maba.seleksi,
    lulusSeleksi: state => state.maba.lulusSeleksi
  },
  mutations: {
    SET_DATA_MABA: (state, maba) => (state.maba = maba),
    SET_NEW_MHS: (state, mhs) => state.mhs.unshift(mhs)
  },
  actions: {
    async loadDataMaba({ commit }, tahun) {
      const response = await axios.get(base_uri, {
        params: {
          tahun: tahun
        }
      });
      commit("SET_DATA_MABA", response.data);
    },
    async singron({ commit }, formData) {
      const response = await axios.post(base_url, formData, {
        headers: {
          "API-KEY": "alkuja07"
          // 'content-type': 'application/x-www-form-urlencoded'
          // "Content-Type": "multipart/form-data",
        }
      });
      commit("SET_NEW_MHS", response.data);
    }
  }
};
export default maba;
