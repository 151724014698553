<template>
  <v-app>
    <router-view> </router-view>
    <vue-progress-bar></vue-progress-bar>
  </v-app>
</template>
<script>
export default {
  name: "App"
};
</script>
